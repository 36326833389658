#root,
.app {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.main-layout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 0 auto;
  grid-template-rows: 4rem auto;

  &__header {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
  }
  &__container {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 4rem);

    .content-width {
      width: 70%;
      &--mobile {
        width: 90%;
      }
    }
  }
}

.Collapsible__contentOuter {
  margin-bottom: 10px;
}
.rc-table-tbody .rc-table-row:not(.solved):hover {
  background-color: #e9f3ea;
}

.rc-table {
  border: 1px solid;
  background: rgb(239, 249, 249);
  color: black;
  border-top: 0;
  &-thead {
    border-bottom: 1px solid lightgray;
    background: lightgray;
  }
  &-tbody {
    .rc-table-row:not(:last-child) {
      border-bottom: 1px solid lightgray;
    }
    .solved {
      background: #e0f0e2;
    }
  }
  .rc-table-cell:not(:last-child) {
    border-right: 1px solid lightgray;
  }
  .rc-table-cell {
    padding: 10px;
  }
}

.rc-checkbox-input {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.question-link {
  cursor: pointer;
  font-weight: 600;
  color: rgb(119, 119, 240);
  &:hover {
    color: rgb(121, 121, 207);
  }
}

.difficulty {
  text-transform: capitalize;
  width: fit-content;
  text-align: center;
  border-radius: 30px;
  border: 1px solid;
  width: 88px;
  &--easy {
    color: rgb(62, 169, 62);
    background: rgb(213, 244, 213);
  }
  &--medium {
    color: rgb(171, 164, 54);
    background: rgb(243, 243, 204);
  }
  &--hard {
    color: rgb(188, 70, 70);
    background: rgb(249, 230, 230);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.logo-img {
  transition: transform 0.7s ease-in-out;
}
.logo-img:hover {
  transform: rotate(360deg);
}

.drawer-content {
  height: calc(100vh - 134px);
  overflow-y: scroll;
}

.codeblock {
  .hljs {
    font-size: 16px !important;
  }

  code {
    border-radius: 7px;
    border: 1px solid;
    color: rgb(230, 227, 227);
    .hljs {
      &-built_in {
        color: orange;
      }
      &-keyword {
        color: #0deef6;
      }
      &-number {
        color: #5cff02;
      }
      &-comment {
        color: #7e958d;
      }
    }
  }
}
